import {isRouteErrorResponse} from '@remix-run/react';

import {notify} from '~/foundation/AppSetupContext/context';

/**
 * Will log to bugsnag for unknown errors.
 * For Promises in particular, we only want to know if they fail.
 */
export function logUnknownError(error: unknown) {
  const logError = (unknownData: unknown) => {
    const usableError = convertUnknownToError(unknownData);
    if (usableError) {
      notify(usableError);
    }
  };

  // lets ignore all Promises, unless they reject, then log what they reject with
  if (error instanceof Promise) {
    error.catch((promiseError) => {
      logError(promiseError);
    });
  } else {
    logError(error);
  }
}

export function convertUnknownToError(error: unknown) {
  if (!error) {
    return;
  }

  if (!isReportableError(error)) {
    return;
  }

  if (
    typeof error === 'object' &&
    'error' in error &&
    error.error instanceof Error
  ) {
    return error.error;
  }

  if (isRouteErrorResponse(error)) {
    const {data, statusText, status} = error;
    return new Error(data.message || `${status} error: ${statusText}`);
  }

  if (error instanceof Error) {
    return error;
  }

  if (typeof error === 'string') {
    return new Error(error);
  }

  if (error instanceof Response) {
    return new Error(
      `Response Error: ${error.status} ${error.statusText} (${error.ok ? 'Okay' : 'Not Okay'})`,
    );
  }

  try {
    const errorString = error?.constructor?.name
      ? `Unknown error: ${typeof error} (${error.constructor.name}) - ${JSON.stringify(error)}`
      : `Unknown error: ${typeof error} - ${JSON.stringify(error)}`;

    return new Error(errorString);
  } catch {
    // Fallback if JSON.stringify fails
    return new Error(
      `Unknown error of type: ${typeof error} (unable to stringify)`,
    );
  }
}

function isReportableError(error: unknown) {
  if (!error) {
    return false;
  }

  const is404 = isRouteErrorResponse(error) && error.status === 404;

  // ignore all 404 errors, as we have a lot of url sniffing errors, so ignore them.
  return !is404;
}
